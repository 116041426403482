import type { ControllerFlowAPI } from '@wix/yoshi-flow-editor';
import type { NavigateToParams } from 'root/clientTypes';
import { APP_DEF_ID } from 'root/consts';

export const goToPage = (flowAPI: ControllerFlowAPI) => {
  const sectionId = 'menus';
  const sectionUrl = flowAPI.controllerConfig.wixCodeApi.site.getSectionUrl({
    sectionId,
    appDefinitionId: APP_DEF_ID,
  });
  return async ({ menuId, urlQueryParam }: NavigateToParams) => {
    const { relativeUrl } = await sectionUrl;
    let queryParam;
    if (urlQueryParam) {
      queryParam = `menu=${urlQueryParam}`;
    } else {
      queryParam = `menuId=${menuId}`;
    }
    flowAPI.controllerConfig.wixCodeApi.location.to?.(`${relativeUrl}?${queryParam}`);
  };
};
