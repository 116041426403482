import type { EditorSDK, TPAPageId } from '@wix/platform-editor-sdk';
import type { ISiteApis, PlatformControllerFlowAPI, StructurePage } from '@wix/yoshi-flow-editor';
import type { BiReferral } from 'root/components/Menus/Settings/consts';
import type { ILayoutStylesParams } from 'root/components/Menus/Settings/Tabs/LayoutTab/LayoutStylesParams';
import type { MenuListNavigation, Alignment } from 'root/consts';
import { ALIGNMENT_VALUE_MAP, NAVIGATION_VALUE_MAP, PAGE_DATA } from 'root/consts';
import type { Preset } from './presets';
import { getPresetName } from './presets';

export const getHostingEnvironment = (environment: PlatformControllerFlowAPI['environment']) => {
  if (environment.isEditor) {
    return 'editor';
  } else if (environment.isEditorX) {
    return 'editor x';
  } else if (environment.isADI) {
    return 'adi';
  } else if (environment.isPreview) {
    return 'preview';
  } else if (environment.isMobile) {
    return 'mobile';
  } else if (environment.isViewer) {
    return 'viewer';
  } else {
    return undefined;
  }
};

export const getIsCurrentPageMenusDefaultPage = async (site: ISiteApis) => {
  const allPages = (await site.getSiteStructure({ includePageId: true })).pages as (StructurePage & {
    tpaPageId: TPAPageId;
  })[];
  const currentPageTpaId = allPages.find((page) => page.id === site.currentPage?.id)?.tpaPageId;
  return currentPageTpaId === PAGE_DATA.pageId;
};

export const getSitePage = async (pages?: EditorSDK['pages']) => {
  const pageRef = await pages?.getCurrent('token');
  const sitePage = pageRef && (await pages?.getPageData('token', { pageRef }));
  return sitePage;
};

export const getSitePageName = async (pages?: EditorSDK['pages']) => {
  const sitePage = await getSitePage(pages);
  return sitePage?.title;
};

export const getSiteData = async (
  editorSDK: EditorSDK | null
): Promise<{
  sitePageName?: string;
  pageId?: string;
}> => {
  if (editorSDK) {
    const sitePage = await getSitePage(editorSDK?.pages);
    return {
      sitePageName: sitePage?.title,
      pageId: sitePage?.id,
    };
  }
  return {
    sitePageName: undefined,
    pageId: undefined,
  };
};

export const getWidgetInstanceId = async (editorSDK: EditorSDK | null) => {
  if (editorSDK) {
    const controllerRef = (await editorSDK?.controllers.listAllControllers('', { includeTPAWidget: true }))?.[0];
    const widgetRefId = controllerRef.controllerRef.id;
    return widgetRefId;
  }
};

export const addReferralToUrl = (url: string, queryReferralInfo?: BiReferral) => {
  if (queryReferralInfo) {
    const params = new URLSearchParams({ referralInfo: queryReferralInfo }).toString();
    const urlWithReferalInfo = `${url}?${params}`;
    return urlWithReferalInfo;
  }
  return url;
};

export const getLayoutStyleParamsForBi = (layoutStyleParams: ILayoutStylesParams) => {
  const preset = Number(layoutStyleParams.preset) as Preset;
  const layoutName = getPresetName(preset);
  const gridLayoutParams = {
    layoutSettings: JSON.stringify({
      maxItemsPerRow: Number(layoutStyleParams.gridNumOfColumns),
      spaceBetweenColumns: Number(layoutStyleParams.gridItemsColumnGap),
      spaceBetweenRows: Number(layoutStyleParams.gridItemsRowGap),
      spaceBetweenSections: Number(layoutStyleParams.gridSectionsGap),
    }),
    menusListNavigation: JSON.stringify({
      layout: NAVIGATION_VALUE_MAP[Number(layoutStyleParams.gridMenuNavigationLayout) as MenuListNavigation],
      alignment: ALIGNMENT_VALUE_MAP[Number(layoutStyleParams.gridMenuNavigationAlignment) as Alignment],
      fitTabsWidthToLists: Boolean(layoutStyleParams.gridMenuNavigationFullWidthTab),
    }),
    menuLayout: JSON.stringify({
      horizontalPadding: Number(layoutStyleParams.gridMenuHorizontalPadding),
      verticalPadding: Number(layoutStyleParams.gridMenuVerticalPadding),
      headerAlignment: ALIGNMENT_VALUE_MAP[Number(layoutStyleParams.gridMenuAlignment) as Alignment],
    }),
    sectionLayout: JSON.stringify({
      backgroundPaddings: Number(layoutStyleParams.gridSectionPadding),
      headerAlignment: ALIGNMENT_VALUE_MAP[Number(layoutStyleParams.gridSectionAlignment) as Alignment],
    }),
    itemLayout: JSON.stringify({
      backgroundPaddings: Number(layoutStyleParams.gridItemPadding),
      alignment: ALIGNMENT_VALUE_MAP[Number(layoutStyleParams.gridItemAlignment) as Alignment],
      detailsSidePadding: Number(layoutStyleParams.gridItemDetailsPadding),
    }),
    itemImage: JSON.stringify({
      imageBottomPaddings: Number(layoutStyleParams.gridItemImageBottomPadding),
      imageRatio: Number(layoutStyleParams.gridItemImageRatio),
    }),
  };

  const centerLayoutParams = {
    layoutSettings: JSON.stringify({
      spaceBetweenRows: Number(layoutStyleParams.centerItemsRowGap),
      spaceBetweenSections: Number(layoutStyleParams.centerSectionsGap),
    }),
    menusListNavigation: JSON.stringify({
      layout: NAVIGATION_VALUE_MAP[Number(layoutStyleParams.centerMenuNavigationLayout) as MenuListNavigation],
      fitTabsWidthToLists: Boolean(layoutStyleParams.centerMenuNavigationFullWidthTab),
    }),
    menuLayout: JSON.stringify({
      horizontalPadding: Number(layoutStyleParams.centerMenuHorizontalPadding),
      verticalPadding: Number(layoutStyleParams.centerMenuVerticalPadding),
    }),
    sectionLayout: JSON.stringify({
      backgroundPaddings: Number(layoutStyleParams.centerSectionPadding),
      topDividerMargin: Number(layoutStyleParams.centerSectionDividerMargin),
    }),
    itemLayout: JSON.stringify({
      backgroundPaddings: Number(layoutStyleParams.centerItemPadding),
      detailsSidePadding: Number(layoutStyleParams.centerItemDetailsPadding),
    }),
  };

  const columnsLayoutParams = {
    layoutSettings: JSON.stringify({
      numberOfColumns: Number(layoutStyleParams.columnsNumOfColumns),
      spaceBetweenColumns: Number(layoutStyleParams.columnsColumnsGap),
      spaceBetweenRows: Number(layoutStyleParams.columnsItemsGap),
      spaceBetweenSections: Number(layoutStyleParams.columnsSectionsGap),
    }),
    menusListNavigation: JSON.stringify({
      layout: NAVIGATION_VALUE_MAP[Number(layoutStyleParams.columnsMenuNavigationLayout) as MenuListNavigation],
      alignment: ALIGNMENT_VALUE_MAP[Number(layoutStyleParams.columnsMenuNavigationAlignment) as Alignment],
      fitTabsWidthToLists: Boolean(layoutStyleParams.columnsMenuNavigationFullWidthTab),
    }),
    menuLayout: JSON.stringify({
      horizontalPadding: Number(layoutStyleParams.columnsMenuHorizontalPadding),
      verticalPadding: Number(layoutStyleParams.columnsMenuVerticalPadding),
      headerAlignment: ALIGNMENT_VALUE_MAP[Number(layoutStyleParams.columnsMenuAlignment) as Alignment],
    }),
    sectionLayout: JSON.stringify({
      backgroundPaddings: Number(layoutStyleParams.columnsSectionPadding),
      headerAlignment: ALIGNMENT_VALUE_MAP[Number(layoutStyleParams.columnsSectionAlignment) as Alignment],
    }),
    itemLayout: JSON.stringify({
      backgroundPaddings: Number(layoutStyleParams.columnsItemPadding),
    }),
    itemImage: JSON.stringify({
      imageRatio: Number(layoutStyleParams.columnsItemImageRatio),
    }),
  };

  return { preset, layoutName, columnsLayoutParams, centerLayoutParams, gridLayoutParams };
};
