export enum Preset {
  Columns = 0,
  Grid = 1,
  Center = 2,
  Strip = 3,
}

export const getPresetName = (preset: Preset) => {
  switch (preset) {
    case Preset.Grid:
      return 'grid';
    case Preset.Columns:
      return 'columns';
    case Preset.Center:
      return 'center';
    case Preset.Strip:
      return 'strip';
    default:
      return '';
  }
};
