import { SettingsParamType, createSettingsParams } from '@wix/tpa-settings';
import { MENUS_DISPLAY_OPTIONS } from './consts';

export type IMenusSettingsParams = {
  menusDisplayOption: SettingsParamType.String;
  menusDisplayOrder?: SettingsParamType.Object;
};

export type MenuOrder = { id: string; checked: boolean };

export const MenusSettingsParams = createSettingsParams<IMenusSettingsParams>({
  menusDisplayOrder: {
    type: SettingsParamType.Object,
    getDefaultValue: () => undefined,
  },
  menusDisplayOption: {
    type: SettingsParamType.String,
    getDefaultValue: () => MENUS_DISPLAY_OPTIONS.all,
  },
});
