import { ITEM_TYPES } from '@wix/advanced-seo-utils';
import type { ControllerFlowAPI, ISeo } from '@wix/yoshi-flow-editor';
import type { PopulatedMenu, PopulatedSection } from 'root/apiTypes';
import type { MenuOrder } from 'root/components/Menus/Settings/Tabs/MenusTab/MenusSettingsParams';
import { MENUS_EXTENDED_FIELDS_NAME_SPACE } from 'root/consts';

export const getMenusSEOData = ({
  displayedMenu,
  pageUrl,
  multilingual,
  menusDisplayOrder,
  firstMenu,
}: {
  displayedMenu: PopulatedMenu;
  pageUrl: string;
  multilingual: ControllerFlowAPI['environment']['multilingual'];
  menusDisplayOrder?: MenuOrder[];
  firstMenu?: PopulatedMenu;
}) => {
  const menuItems = displayedMenu.sections.flatMap((section: PopulatedSection) => section.items);
  const isMainMenu = menusDisplayOrder?.[0].id === displayedMenu.id || firstMenu?.id === displayedMenu.id;

  return {
    menu: {
      ...displayedMenu,
      url: pageUrl,
      isMainMenu,
    },
    items: menuItems,
    translatedPages: multilingual,
  };
};

export const sendSEOData = ({
  baseUrl,
  siteUrl,
  isCurrentPageMenusDefaultPage,
  multilingual,
  menusDisplayOrder,
  menus,
  seo,
}: {
  baseUrl: string;
  siteUrl: string;
  isCurrentPageMenusDefaultPage: boolean;
  multilingual: ControllerFlowAPI['environment']['multilingual'];
  menusDisplayOrder?: MenuOrder[];
  menus: PopulatedMenu[];
  seo: ISeo;
}) => {
  const displayedMenu = menus.find((menu) => menu.id === menusDisplayOrder?.[0].id) ?? menus[0];
  const pageBaseUrl = baseUrl;
  const urlQueryParam = `?menu=${encodeURIComponent(displayedMenu.urlQueryParam ?? '')}`;
  const fullPageUrl = pageBaseUrl + siteUrl + urlQueryParam;
  const itemData = getMenusSEOData({
    displayedMenu,
    pageUrl: fullPageUrl,
    multilingual,
    menusDisplayOrder,
    firstMenu: menus[0],
  });

  seo.renderSEOTags({
    itemType: isCurrentPageMenusDefaultPage ? ITEM_TYPES.RESTAURANTS_MENU_PAGE : ITEM_TYPES.RESTAURANTS_MENU_COMPONENT,
    itemData,
    seoData: displayedMenu?.extendedFields?.namespaces?.[MENUS_EXTENDED_FIELDS_NAME_SPACE]?.seoData || {},
  });
};
