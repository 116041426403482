import type { ShowcasePopulationLevel } from 'root/apiTypes';
import type { PageData, Range } from './clientTypes';

export const MAX_NUMBER_OF_ENTITIES_FROM_SERVER = 500;
export const DEFAULT_APP_WIDTH = 980;

export const SHOWCASE_POPULATION_LEVEL: ShowcasePopulationLevel = {
  labels: true,
  modifierGroups: false,
  modifiers: false,
  priceVariants: true,
};

export const MENU_WIDGET_NAME = 'Menu';
export const PAGE_NAME = 'menus';

export enum MenuGFPPButtons {
  Settings = 'menu settings button',
  Manage = 'manage menus button',
}
export enum MenuPanelName {
  Settings = 'menu settings',
  Manage = 'manage menus',
}

export const APP_DEF_ID = 'b278a256-2757-4f19-9313-c05c783bec92';

export const WIDGET_IDS = {
  menus: '3f7c620e-526a-4dbb-afff-6fb77bfa8dcc',
};

export const PAGE_DATA: PageData = {
  pageId: 'Menus',
  title: 'app.page.title',
  widgetId: WIDGET_IDS.menus,
  pageUriSEO: 'menus-ooi',
};

export enum Alignment {
  Left,
  Center,
  Right,
}

export enum ImageRatio {
  '16:9' = 16 / 9,
  '4:3' = 4 / 3,
  '1:1' = 1,
  '3:4' = 3 / 4,
  '9:16' = 9 / 16,
}

export const BORDER_INPUT_WIDTH = 170;

export enum MenuListNavigation {
  Tabs = 0,
  Dropdown = 1,
}

export enum FieldName {
  MaxItems = 'maxItemsPerRow',
  ColumnsNumber = 'numberOfColumns',
  ColumnsGap = 'columnsGap',
  ColumnsSectionGap = 'columnsSectionGap',
  ColumnsItemsGap = 'columnsItemsGap',
  ColumnsItemPadding = 'columnsItemPadding',
  SectionsGap = 'sectionsGap',
  ItemsGap = 'itemsGap',
  SectionPadding = 'sectionPadding',
  ItemPadding = 'itemPadding',
  ItemDetailsPadding = 'itemDetailsPadding',
  Default = 'default',
  NavigationLayout = 'navigationLayout',
  NavigationFullWidthTab = 'navigationFullWidthTab',
  NavigationAlignment = 'navigationAlignment',
  MenuAlignment = 'menuAlignment',
  SectionAlignment = 'sectionAlignment',
  ItemAlignment = 'itemAlignment',
  menuBackgroundHorizontalPadding = 'menuBackgroundHorizontalPadding',
  menuBackgroundVerticalPadding = 'menuBackgroundVerticalPadding',
  ItemImageBottomPadding = 'itemImageBottomPadding',
  NavigationTabsText = 'navigationTabsText',
  NavigationTabsIndicator = 'navigationTabsIndicator',
  NavigationStyle = 'navigationStyle',
  NavigationDropdownText = 'navigationDropdownText',
  NavigationDropdownBackground = 'navigationDropdownBackground',
  NavigationDropdownBorderColor = 'navigationDropdownBorderColor',
  NavigationDropdownBorderWidth = 'navigationDropdownBorderWidth',
  NavigationDropdownBorderRadius = 'navigationDropdownBorderRadius',
  MenusBackgroundColor = 'menusBackgroundColor',
  MenusBorder = 'menusBorder',
  MenusBorderRadius = 'menusBorderRadius',
  MenusTitle = 'menusTitle',
  MenusDescription = 'menusDescription',
  SectionsBackgroundColor = 'sectionsBackgroundColor',
  SectionsBorder = 'sectionsBorder',
  SectionsBorderRadius = 'sectionsBorderRadius',
  SectionsTopDividerWidth = 'sectionsTopDividerWidth',
  SectionsTopDividerColor = 'sectionsTopDividerColor',
  SectionsBottomDividerWidth = 'sectionsBottomDividerWidth',
  SectionsBottomDividerColor = 'sectionsBottomDividerColor',
  SectionsTitleColor = 'sectionsTitleColor',
  SectionsDescriptionColor = 'sectionsDescriptionColor',
  SectionDividerMargin = 'sectionDividerMargin',
  ItemsBackgroundColor = 'itemsBackgroundColor',
  ItemsBorder = 'itemsBorder',
  ItemsBorderRadius = 'itemsBorderRadius',
  ItemsTitle = 'itemsTitle',
  ItemsDescription = 'itemsDescription',
  ItemsPrice = 'itemsPrice',
  ItemsDividerWidth = 'itemsDividerWidth',
  ItemsDividerColor = 'itemsDividerColor',
  ItemsImageRadius = 'itemsImageRadius',
  VariantTitle = 'variantTitle',
  VariantPrice = 'variantPrice',
  VariantDividerColor = 'variantDividerColor',
  LabelTitle = 'labelTitle',
  LabelBackgroundColor = 'labelBackgroundColor',
  LabelBorderColor = 'labelBorderColor',
  LabelBorderWidth = 'labelBorderWidth',
  LabelBorderRadius = 'labelBorderRadius',
  LabelIconColor = 'labelIconColor',
  LabelIconSize = 'labelIconSize',
  LabelSpaceBetween = 'labelSpaceBetween',
  LabelVerticalPadding = 'labelVerticalPadding',
  LabelHorizontalPadding = 'labelHorizontalPadding',
}
export const defaultFontSizeRange: Range = { min: 12, max: 100 };

export enum NavigationDropdownStyle {
  Underline,
  Box,
}

export enum MenusDisplay {
  All = 'show all menus',
  Specific = 'show specific menus',
}

export const NAVIGATION_VALUE_MAP: Record<MenuListNavigation, string> = {
  [MenuListNavigation.Tabs]: 'tabs',
  [MenuListNavigation.Dropdown]: 'dropdown',
};

export const ALIGNMENT_VALUE_MAP = {
  [Alignment.Left]: 'left',
  [Alignment.Center]: 'center',
  [Alignment.Right]: 'right',
};

export const MENUS_EXTENDED_FIELDS_NAME_SPACE = '@restaurants/menus';
