import { getSection, querySections } from '@wix/ambassador-restaurants-menus-v1-section/http';
import type { Section } from 'root/apiTypes';
import type { HttpClientFactory, SectionCursorPagingMetadata } from './utils/types';
import { getIdsChunks } from './utils/entityUtils';
import { MAX_NUMBER_OF_ENTITIES_FROM_SERVER } from 'root/consts';

export const SectionsClient: HttpClientFactory<Section> = (httpClient) => ({
  get: ({ id }) => {
    return httpClient
      .request(getSection({ sectionId: id }))
      .then((res) => {
        const {
          data: { section = {} },
        } = res;

        return { data: section as Section };
      })
      .catch((e) => {
        throw new Error(`Error when calling getSection with id ${id}- ${e}`);
      });
  },
  getAll: async ({ ids, paging }) => {
    let sections: Section[] = [];
    let pagingMetadata: SectionCursorPagingMetadata | undefined;

    if (ids?.length) {
      const idChunks = getIdsChunks(ids, MAX_NUMBER_OF_ENTITIES_FROM_SERVER);
      const sectionsInChunks = await Promise.all(
        idChunks.map(async (chunk) => {
          const { data } = await httpClient.request(
            querySections({
              query: {
                filter: {
                  visible: { $eq: true },
                  id: {
                    $in: chunk || [],
                  },
                },
              },
            })
          );
          return data.sections || [];
        })
      );

      sections.push(...sectionsInChunks.flatMap((section) => section as Section[]));
    } else {
      const { data } = await httpClient.request(
        querySections({
          query: { cursorPaging: paging, filter: { visible: true } },
        })
      );
      sections = data.sections as Section[];
      pagingMetadata = data.pagingMetadata;
    }

    return {
      data: sections || [],
      pagingMetadata,
    };
  },
});
